<template>
  <div id="interests-card" class="card my-4">
    <div class="card-body">
      <h2 class="mb-4 text-center">Acesso {{ expiresLabel }}</h2>

      <div class="row">
        <div class="col-lg-3 col-12"></div>
        <div class="col-lg-3 col-12" v-for="plan in plans" :key="plan.id">
          <div
            class="card mini-stat bg-dark card-clickable"
            @click.prevent="selectedPlan = plan"
            :class="[
              {
                'active-card': selectedPlan.id === plan.id,
              },
            ]"
          >
            <div class="card-body">
              <div class="text-center">
                <h5 class="text-white-50">
                  {{ plan.name }}
                </h5>
                <span class="text-white" v-html="plan.description"></span>
                <h5 class="text-white">
                  {{ `R$ ${plan.value}` }}
                </h5>
              </div>
              <div
                v-if="
                  currentUser.userPlan &&
                  currentUser.userPlan.plan_id === plan.id &&
                  currentUser.userPlan.status &&
                  currentUser.userPlan.status !== 'canceled'
                "
                class="mini-stat-label bg-success"
              >
                <p class="text-white mb-0">
                  Atual
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="p-2">
          <button
            @click.prevent="goToCheckout()"
            class="btn btn-success btn-lg mx-auto d-block my-2"
            :disabled="this.selectedPlan === null"
          >
            <i class="mdi mdi-check"></i>
            Assinar
          </button>
        </div>
        <div
          class="p-2"
          v-if="
            currentUser.userPlan.status &&
            currentUser.userPlan.status !== 'canceled'
          "
        >
          <button
            @click.prevent="cancelPlan()"
            class="btn btn-danger btn-lg mx-auto d-block my-2"
            :disabled="this.selectedPlan === null"
          >
            <i class="mdi mdi-close"></i>
            Cancelar plano
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { authComputed } from "@/state/helpers";
import moment from "moment";

export default {
  name: "Plans",

  components: {},
  data() {
    return {
      loading: true,
      plans: [],
      selectedPlan: {},
    };
  },

  computed: {
    ...authComputed,

    expiresLabel() {
      if (!this.currentUser.subscription) {
        return "expirado";
      }

      const now = moment.utc();
      const m = moment.utc(this.currentUser.subscription);

      if (now.isAfter(m)) {
        return "expirado";
      }

      const days = m.diff(now, "days");

      if (days <= 1) {
        const hours = m.diff(now, "hours");
        const minutes = m.diff(now, "minutes");
        return `acaba em ${hours} hora(s) e ${minutes % 60} minuto(s)`;
      }

      return `acaba em ${days} dia(s)`;
    },
  },

  created() {
    api
      .getAllPlans()
      .then((plans) => {
        this.loading = false;
        this.plans = plans;
        if (
          this.currentUser.userPlan &&
          this.currentUser.userPlan.status !== "canceled"
        ) {
          this.selectedPlan = this.plans.find(
            (plan) => plan.id === this.currentUser.userPlan.plan_id
          );
        }
      })
      .catch(() => {
        this.loading = false;
        this.$swal("Opa", "Falha ao carregar planos", "error");
      });
  },

  methods: {
    goToCheckout() {
      this.$router.push(`cobranca/${this.selectedPlan.code}`);
    },
    cancelPlan() {
      api
        .cancelPlan()
        .then((plan) => {
          this.plan = plan;
          return this.$store.dispatch("auth/validate");
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao cancelar plano", "error");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.interest-text {
  height: 50px;
}

span > ul {
  list-style: none;
  padding: 0;
  align-content: center;
}

span > ul > li {
  text-transform: uppercase;
}

.interest-img-fit {
  border-radius: 0.25rem;
  height: 200px;
  object-fit: cover;
  transition: filter 1s ease-in;
}
.interest-img-disabled {
  filter: grayscale(1);
  opacity: 0.4;
  transition: ease-in 0.4s;
}

.interest-img-enabled {
  filter: grayscale(0);
  opacity: 1;
  transition: ease-out 0.4s;
}

.card-clickable {
  cursor: pointer;
}

.card-clickable:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
}

.active-card {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.75);
  transition: ease-in 0.4s;
  // transform: translate(-4px, -4px);
  transform: translateY(-4px);
  //transform: scale(1.02);
}

.mini-stat .mini-stat-label {
  position: absolute;
  right: 0;
  top: 18px;
  padding: 2px 10px 2px 32px;
  -webkit-clip-path: polygon(
    100% 0,
    100% 50%,
    100% 100%,
    0 100%,
    24% 50%,
    6% 0
  );
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 24% 50%, 6% 0);
}
</style>
