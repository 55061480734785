<template>
  <Layout>
    <Plans />
  </Layout>
</template>
<script>
import Layout from "../layouts/main";
import Plans from "@/components/Plans";

export default {
  components: { Layout, Plans },
};
</script>
